import React, { useState, useEffect } from 'react';
import Michenaud from "../medias/michenaud.svg";
import Advice from "../elements/advice";
import Wallpaper from "../elements/wallpaper";
import Product from "../elements/product";
import Carousel from "../elements/carrousel"; // Assurez-vous que le chemin est correct
import { Link } from "react-router-dom";
import Wall from "../medias/wall.png";
import ReactPlayer from "react-player";
import DAW from "../medias/daw.png";
import Plus from "../medias/+.svg";
import Pro from "../medias/pro.svg";
import { BrowserRouter as Router } from 'react-router-dom';

function Loader() {

  const [showPopup, setShowPopup] = useState(false);
  const [newsletterOn, setNewsletterOn] = useState(true);
  const audioSrc = "../medias/flamenco_example.wav";

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleSubscriptionSelect = (subscriptionType) => {
    localStorage.setItem('selectedSubscription', subscriptionType);
  };

  return (
    <div>
 

    <p id="pricing" className="title-center mt-24 mb-20 ">
        Pricing
        </p>
        <div className="  text-white font-mb p-4 ">
        <div className="grid grid-cols-1 desktop:grid-cols-2 gap-4 max-w-fit mx-auto">

            {/* Quarterly Subscription */}
            <div className="bg-gray-900 rounded-3xl shadow-xl p-10">
              <h2 className="font-akira text-3xl mb-4 relative">Studio<img src={Plus} className="absolute w-10 top-0 left-36" alt="Icon" /> </h2>
              <p className="text-gray-400 mb-6">15-DAY MONEY-BACK GUARANTEE</p>
              <div className="flex text-4xl font-akira mb-4">            <span>
              $49,90<p className="text-gray-400 text-lg font-mt font-bold ml-2  inline-block">VAT included</p>
            </span></div>
              <div className=" mx-auto">
            <Link
                onClick={() => {
                  scrollToTop();
                  handleSubscriptionSelect("price_1Q3e4uCdrshQWAUbVZG7Eizg");
                }}
                to="/studiosignup"
              className="btn-indigo text-center mt-6 mx-auto text-white inline-block"
            >
              Buy now
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="inline-block ml-2 mr-1 -translate-y-0.5"
                viewBox="0 0 16 16"
              >
                <path d="M7.462 0H0v7.19h7.462zM16 0H8.538v7.19H16zM7.462 8.211H0V16h7.462zm8.538 0H8.538V16H16z" />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                fill="currentColor"
                className="inline-block  mr-1 -translate-y-0.5"
                viewBox="0 0 16 16"
              >
                <path d="M11.182.008C11.148-.03 9.923.023 8.857 1.18c-1.066 1.156-.902 2.482-.878 2.516s1.52.087 2.475-1.258.762-2.391.728-2.43m3.314 11.733c-.048-.096-2.325-1.234-2.113-3.422s1.675-2.789 1.698-2.854-.597-.79-1.254-1.157a3.7 3.7 0 0 0-1.563-.434c-.108-.003-.483-.095-1.254.116-.508.139-1.653.589-1.968.607-.316.018-1.256-.522-2.267-.665-.647-.125-1.333.131-1.824.328-.49.196-1.422.754-2.074 2.237-.652 1.482-.311 3.83-.067 4.56s.625 1.924 1.273 2.796c.576.984 1.34 1.667 1.659 1.899s1.219.386 1.843.067c.502-.308 1.408-.485 1.766-.472.357.013 1.061.154 1.782.539.571.197 1.111.115 1.652-.105.541-.221 1.324-1.059 2.238-2.758q.52-1.185.473-1.282" />
                <path d="M11.182.008C11.148-.03 9.923.023 8.857 1.18c-1.066 1.156-.902 2.482-.878 2.516s1.52.087 2.475-1.258.762-2.391.728-2.43m3.314 11.733c-.048-.096-2.325-1.234-2.113-3.422s1.675-2.789 1.698-2.854-.597-.79-1.254-1.157a3.7 3.7 0 0 0-1.563-.434c-.108-.003-.483-.095-1.254.116-.508.139-1.653.589-1.968.607-.316.018-1.256-.522-2.267-.665-.647-.125-1.333.131-1.824.328-.49.196-1.422.754-2.074 2.237-.652 1.482-.311 3.83-.067 4.56s.625 1.924 1.273 2.796c.576.984 1.34 1.667 1.659 1.899s1.219.386 1.843.067c.502-.308 1.408-.485 1.766-.472.357.013 1.061.154 1.782.539.571.197 1.111.115 1.652-.105.541-.221 1.324-1.059 2.238-2.758q.52-1.185.473-1.282" />
              </svg>{" "}
          
            </Link>
          </div>
          <div className=" border-t-2 mt-28 border-gray-500">
            <p className="font-bold mt-6">FEATURES</p>
            <p className="text-gray-400 mt-4"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#9187BA" class=" translate-x-0.5 translate-y-[3px] float-left mr-2" viewBox="0 0 16 16"><path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path><path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"></path></svg>Full access to all presets</p>
            <p className="text-gray-400 mt-4"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#9187BA" class=" translate-x-0.5 translate-y-[3px] float-left mr-2" viewBox="0 0 16 16"><path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path><path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"></path></svg>Regular themed updates</p>
            <p className=" mt-4 text-rose-200"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#f43f5e" class=" translate-x-0.5 translate-y-[3px] float-left mr-2 text-rose-200" viewBox="0 0 16 16"><path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path><path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"></path></svg>Max 3 midi downloads</p>
          
            
           
          </div>
            </div>
            <div className=" bg-gradient-to-br from-gray-800 to-indigo-600/50 backdrop-blur-lg rounded-3xl shadow-xl p-10 border-2 border-indigo-600">
              <h2 className="font-akira text-3xl mb-4 relative">Studio<img src={Pro} className="absolute w-16 top-2 left-36" alt="Icon" /> </h2>
              
              <p className="text-gray-400 mb-6">15-DAY MONEY-BACK GUARANTEE</p>
              <div className="flex text-4xl font-akira mb-4">
            <span>
              $69.90<p className="text-gray-400 text-lg font-mt font-bold ml-2  inline-block">VAT included</p>
            </span>
          </div>
              <div className=" mx-auto">
            <Link
                onClick={() => {
                  scrollToTop();
                  handleSubscriptionSelect("price_1Q3e5JCdrshQWAUbYfeVV5T5");
                }}
                to="/studiosignup"
              className="btn-indigo text-center mt-6 mx-auto text-white inline-block"
            >
              Buy now
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="inline-block ml-2 mr-1 -translate-y-0.5"
                viewBox="0 0 16 16"
              >
                <path d="M7.462 0H0v7.19h7.462zM16 0H8.538v7.19H16zM7.462 8.211H0V16h7.462zm8.538 0H8.538V16H16z" />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                fill="currentColor"
                className="inline-block  mr-1 -translate-y-0.5"
                viewBox="0 0 16 16"
              >
                <path d="M11.182.008C11.148-.03 9.923.023 8.857 1.18c-1.066 1.156-.902 2.482-.878 2.516s1.52.087 2.475-1.258.762-2.391.728-2.43m3.314 11.733c-.048-.096-2.325-1.234-2.113-3.422s1.675-2.789 1.698-2.854-.597-.79-1.254-1.157a3.7 3.7 0 0 0-1.563-.434c-.108-.003-.483-.095-1.254.116-.508.139-1.653.589-1.968.607-.316.018-1.256-.522-2.267-.665-.647-.125-1.333.131-1.824.328-.49.196-1.422.754-2.074 2.237-.652 1.482-.311 3.83-.067 4.56s.625 1.924 1.273 2.796c.576.984 1.34 1.667 1.659 1.899s1.219.386 1.843.067c.502-.308 1.408-.485 1.766-.472.357.013 1.061.154 1.782.539.571.197 1.111.115 1.652-.105.541-.221 1.324-1.059 2.238-2.758q.52-1.185.473-1.282" />
                <path d="M11.182.008C11.148-.03 9.923.023 8.857 1.18c-1.066 1.156-.902 2.482-.878 2.516s1.52.087 2.475-1.258.762-2.391.728-2.43m3.314 11.733c-.048-.096-2.325-1.234-2.113-3.422s1.675-2.789 1.698-2.854-.597-.79-1.254-1.157a3.7 3.7 0 0 0-1.563-.434c-.108-.003-.483-.095-1.254.116-.508.139-1.653.589-1.968.607-.316.018-1.256-.522-2.267-.665-.647-.125-1.333.131-1.824.328-.49.196-1.422.754-2.074 2.237-.652 1.482-.311 3.83-.067 4.56s.625 1.924 1.273 2.796c.576.984 1.34 1.667 1.659 1.899s1.219.386 1.843.067c.502-.308 1.408-.485 1.766-.472.357.013 1.061.154 1.782.539.571.197 1.111.115 1.652-.105.541-.221 1.324-1.059 2.238-2.758q.52-1.185.473-1.282" />
              </svg>{" "}
              
            </Link>
          </div>
          <div className=" border-t-2 mt-28 border-gray-500">
            <p className="font-bold mt-6">FEATURES</p>
            <p className="text-gray-400 mt-4"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#9187BA" class=" translate-x-0.5 translate-y-[3px] float-left mr-2" viewBox="0 0 16 16"><path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path><path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"></path></svg>Full access to all presets</p>
            <p className="text-gray-400 mt-4"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#9187BA" class=" translate-x-0.5 translate-y-[3px] float-left mr-2" viewBox="0 0 16 16"><path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path><path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"></path></svg>Regular themed updates</p>
            <p className="text-gray-400 mt-4"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#9187BA" class=" translate-x-0.5 translate-y-[3px] float-left mr-2" viewBox="0 0 16 16"><path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path><path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"></path></svg>Unlimited midi download</p>
            
            
           
          </div>
            </div>
            

          </div>
        </div>
        <p className="title-center mt-20">
        Compatible with
        </p>
        <img
            src={DAW}
            className="opacity-80 w-3/4 mx-auto mt-8"
          />
    
    </div>
  );
}

export default Loader;

/*
    <div className={` ${isLoading ? 'opacity-100' : 'opacity-0'} transition-opacity duration-200 pointer-events-none`}>
        <div className='z-[55] fixed top-0 left-0'>
          <div className="flex justify-center items-center w-screen h-screen bg-gray-950">
            <img
              src={LogoAbyss}
              className="desktop:w-72 w-64 animate-bounce"
              alt="logoabyss"
            />
          </div>
        </div>
    </div>
  */